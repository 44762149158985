exports.components = {
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-post-guide-tsx": () => import("./../../../src/pages/post-guide.tsx" /* webpackChunkName: "component---src-pages-post-guide-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-templates-404-404-tsx": () => import("./../../../src/templates/404/404.tsx" /* webpackChunkName: "component---src-templates-404-404-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-guides-tsx": () => import("./../../../src/templates/blog/guides.tsx" /* webpackChunkName: "component---src-templates-blog-guides-tsx" */),
  "component---src-templates-integrations-integrations-tsx": () => import("./../../../src/templates/integrations/integrations.tsx" /* webpackChunkName: "component---src-templates-integrations-integrations-tsx" */)
}

